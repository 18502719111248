<template>
  <div class="mainform">
    <div class="mainHeader">
      <span>关键事项导入</span>
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form perch-mg-form">
      <el-form class="content bascform" label-width="120px">
        <div class="purchClass">
          <el-form-item label="关键事项">
            <div class="clearfix">
              <div class="btnlaft mg-btnlaft">
                <el-upload
                  ref="upload"
                  name="file"
                  :auto-upload="true"
                  :action= 'postUrl'
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  :on-change="handleChansge"
                  :on-success="handSuccess"
                  :on-error="handError"
                  :headers="tokenHeader"
                >
                <el-button size="small" type="primary" plain>导入数据</el-button>
              </el-upload>
              </div>
              <div class="downMb">
                <el-button @click="downCommonTemplate" size="small" type="primary" plain>下载模板</el-button>
              </div>
            </div>
            <div class="excel-tab">
              <el-table :data="bidScoringList" stripe style="width: 100%">
                <el-table-column prop="sequenceNumber" label="行号" width="80px"></el-table-column>
                <el-table-column prop="supperCode" label="供应商编码" width=""></el-table-column>
                <!-- <el-table-column prop="supperName" label="供应商名称" width=""></el-table-column> -->
                <el-table-column prop="purchaseCode" label="采购品类代码" width=""></el-table-column>
                <!-- <el-table-column prop="purchaseName" label="采购品类名称" width=""></el-table-column> -->
                <el-table-column prop="rework" label="实际返修期(天)" width=""></el-table-column>
                <el-table-column prop="quarter" label="发生季度" width=""></el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </div>
        <!-- <div class="action mg-action">
          <el-button class="submit" type="primary" size="medium" @click="preservation">提交并检验</el-button>
        </div> -->
      </el-form>
    </div>
  </div>
</template>
<script>
import Component from '@/assets/js/components.js'
import { baseURL, exportForm } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'ImportData',
  data: function () {
    return {
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/evaluation/reworkMatter/importInfo',
      bidScoringListTitle: [],
      bidScoringList: []
    }
  },
  mounted () {
  },
  onUnmounted () {
  },
  created () {
  },
  watch: {
  },
  methods: {
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$message({
        showClose: true,
        type: 'error',
        message: '只能上传一个文件'
      })
    },
    // 验证上传文件格式
    handleChansge (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message({
          showClose: true,
          type: 'error',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        return false
      }
    },
    // 上传失败提示
    handError () {
      this.$message({
        showClose: true,
        type: 'error',
        message: '文件上传失败'
      })
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        this.bidScoringListTitle = response.data.title
        this.bidScoringList = response.data
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: response.msg
        })
      }
    },

    // 提交并检验
    preservation () {
      console.log('提交并检验')
    },
    // 下载普通物料出入库模板
    downCommonTemplate () {
      exportForm('/api/evaluation/reworkMatter/downloadExcel', 'post').then((res) => {
        try {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.download = '返修期关键事项导入模板.xls'
          eleLink.target = '_blank'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        } catch (error) {
          console.error('download function error!', error)
        }
      })
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .yqgys .content{
    padding: 0 !important;
  }
  .excel-tab{
    margin-top: 10px;
    margin-bottom: 20px;
    :deep(tbody tr td){
      padding: 9px 0;
    }
  }
  .btnlaft{
    float: left;
  }
  .mg-btnlaft{
    margin-right: 10px;
  }
  .mg-form{
    :deep(.el-table th){
      padding: 4px 0;
      background: #ededed;
      font-size: 14px;
    }
  }
</style>
